//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import MixinTeamOptions from "@/mixins/teamOptions";
import MixinWebrtc from "@/mixins/webrtcNew";
import Request from "@/webrtc/server/internal/Request";
import { imgChange } from "@/commons";
export default {
  name: "Dashboard",
  mixins: [MixinTeamOptions, MixinWebrtc],
  components: {
    Header: () => import("@/components/Header"),
    selMember: () => import("@/components/Selectors/MemberSelectCopy"),
    ChatKeywordRank: () => import("./computerData/ChatKeywordRank.vue"),
    ChatTargetRank: () => import("./computerData/ChatTargetRank.vue"),
    SoftwareUsageRank: () => import("./computerData/SoftwareUsageRank.vue"),
    SoftwareActiveRank: () => import("./computerData/SoftwareActiveRank.vue"),
    SiteRank: () => import("./computerData/SiteRank.vue"),
    SearchRank: () => import("./computerData/SearchRank.vue"),
    FileOperateData: () => import("./computerData/FileOperateData.vue"),
    TaskHistory: () => import("./workData/TaskHistory.vue"),
    SensitiveWords: () => import("./workData/SensitiveWords.vue"),
    MessageList: () => import("./workData/MessageList.vue"),
    ReportData: () => import("./workData/ReportData.vue"),
    AttendanceData: () => import("./workData/AttendanceData.vue"),
    TaskData: () => import("./workData/TaskData.vue"),
    ReportDetail: () => import("@/userview/report/reportDetail"),
    ChatHistoryDialog: () => import("@/components/Dialogs/chatHistoryDialog"),
    SoftwareDialog: () => import("@/components/Dialogs/processDataDialog"),
    BrowserHistoryDialog: () =>
      import("@/components/Dialogs/BrowserHistoryDialog"),
    SearchEngineDialog: () => import("@/components/Dialogs/searchEngineDialog"),
    XModal: () => import("@/components/XModal"),
  },
  data() {
    return {
      NeedDataChannel: true,
      tabType: 1,
      tabList: [
        {
          value: 1,
          label: "我的数据",
        },
        {
          value: 2,
          label: "企业数据",
        },
      ],
      loading: false,
      teamOptions: [], //团队选择器
      teamValue: null, //选择的团队
      selMem: [], //选择的成员
      dateRange: [
        new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate() - 7
        ),
        new Date(),
      ],
      dateRange2PickerOptions: {
        disabledDate: (v) => v <= new Date(this.dateRange[0]),
      },
      dialogs: {
        website: {
          open: false,
          options: {},
        },
        browser: {
          open: false,
          options: {},
        },
        keyword: {
          open: false,
          options: {},
        },
        chat: {
          open: false,
          options: {},
        },
        software: {
          open: false,
          title: "软件使用排行 TOP5",
          options: {},
        },
      },
      userInfo: {
        Id: 0,
        Name: "小明",
        Avatar: null,
        Department: "技术研发部",
        ClientStatus: 0,
        LastClientOnline: "2022-10-24 10:22:33",
        Attendance: {
          UpWorkTime: null,
          UpWorkStatus: -1,
          DownWorkTime: null,
          DownWorkStatus: -1,
          TotalTime: 0,
          ShouldEndTime: null,
          WorkStatus: 0,
        },
        AttendanceTotal: {
          Late: 0,
          EarlyLeave: 0,
          Lose: 0,
          Abstained: 0,
          Attendance: 0,
        },
      },
      companyData: {
        Total: 0,
        Online: 0,
        Offline: 0,
        Active: 0,
        Free: 0,
      },
      computerData: {
        chatKeywordRank: [
          {
            Keyword: "哈哈哈哈",
            TotalCount: 12,
            Percentage: 20,
            MouseClickSpeed: 12,
            MouseMoveSpeed: 22,
            KeystrokeSpeed: 33,
          },
          {
            Keyword: "关键字2",
            TotalCount: 12,
            Percentage: 20,
            MouseClickSpeed: 12,
            MouseMoveSpeed: 22,
            KeystrokeSpeed: 33,
          },
          {
            Keyword: "关键字3",
            TotalCount: 12,
            Percentage: 20,
            MouseClickSpeed: 12,
            MouseMoveSpeed: 22,
            KeystrokeSpeed: 33,
          },
          {
            Keyword: "关键字4",
            TotalCount: 12,
            Percentage: 20,
            MouseClickSpeed: 12,
            MouseMoveSpeed: 22,
            KeystrokeSpeed: 33,
          },
          {
            Keyword: "关键字5",
            TotalCount: 12,
            Percentage: 20,
            MouseClickSpeed: 12,
            MouseMoveSpeed: 22,
            KeystrokeSpeed: 33,
          },
        ],
        chatTargetRank: [
          {
            TargetName: "刘武",
            TotalTime: 12,
            Percentage: 20,
            MouseClickSpeed: 12,
            MouseMoveSpeed: 22,
            KeystrokeSpeed: 33,
          },
          {
            TargetName: "王二",
            TotalTime: 15,
            Percentage: 22,
            MouseClickSpeed: 10,
            MouseMoveSpeed: 22,
            KeystrokeSpeed: 33,
          },
          {
            TargetName: "张三",
            TotalTime: 15,
            Percentage: 22,
            MouseClickSpeed: 10,
            MouseMoveSpeed: 22,
            KeystrokeSpeed: 33,
          },
          {
            TargetName: "李四",
            TotalTime: 15,
            Percentage: 22,
            MouseClickSpeed: 10,
            MouseMoveSpeed: 22,
            KeystrokeSpeed: 33,
          },
          {
            TargetName: "王麻子",
            TotalTime: 15,
            Percentage: 22,
            MouseClickSpeed: 10,
            MouseMoveSpeed: 22,
            KeystrokeSpeed: 33,
          },
        ],
        softwareUsageRank: [
          {
            SoftwareName: "微信",
            TotalTime: 120,
            Percentage: 20,
            MouseClickSpeed: 12,
            MouseMoveSpeed: 22,
            KeystrokeSpeed: 33,
          },
          {
            SoftwareName: "微信",
            TotalTime: 120,
            Percentage: 20,
            MouseClickSpeed: 12,
            MouseMoveSpeed: 22,
            KeystrokeSpeed: 33,
          },
          {
            SoftwareName: "微信",
            TotalTime: 120,
            Percentage: 20,
            MouseClickSpeed: 12,
            MouseMoveSpeed: 22,
            KeystrokeSpeed: 33,
          },
          {
            SoftwareName: "微信",
            TotalTime: 120,
            Percentage: 20,
            MouseClickSpeed: 12,
            MouseMoveSpeed: 22,
            KeystrokeSpeed: 33,
          },
          {
            SoftwareName: "微信",
            TotalTime: 120,
            Percentage: 20,
            MouseClickSpeed: 12,
            MouseMoveSpeed: 22,
            KeystrokeSpeed: 33,
          },
        ],
        softwareActiveRank: [
          {
            SoftwareName: "微信",
            TotalTime: 12,
            ActiveTime: 10,
            Percentage: 80,
          },
          {
            SoftwareName: "QQ",
            TotalTime: 30,
            ActiveTime: 22,
            Percentage: 70,
          },
          {
            SoftwareName: "钉钉",
            TotalTime: 22,
            ActiveTime: 45,
            Percentage: 22,
          },
          {
            SoftwareName: "网易云",
            TotalTime: 12,
            ActiveTime: 22,
            Percentage: 77,
          },
          {
            SoftwareName: "酷狗音乐酷狗音乐酷狗音乐",
            TotalTime: 33,
            ActiveTime: 44,
            Percentage: 23,
          },
        ],
        siteRank: [
          {
            Url: "www.baidu.com",
            MouseClickSpeed: 11,
            MouseMoveSpeed: 22,
            KeystrokeSpeed: 24,
            ActivePercentage: 80,
            TimePercentage: 22,
          },
        ],
        searchRank: [
          {
            Keyword: "如何上班摸鱼神器",
            MouseClickSpeed: 11,
            MouseMoveSpeed: 22,
            KeystrokeSpeed: 24,
            ActivePercentage: 80,
            TimePercentage: 22,
          },
        ],
        fileOperateData: {
          Inner: {
            Create: 0,
            Copy: 0,
            Move: 0,
            Delete: 0,
            Rename: 0,
          },
          Outer: {
            USB: 0,
            Network: 0,
          },
        },
      },
      workData: {
        taskHistory: [
          {
            Id: 1,
            Name: "编写首页前端页面",
            StartTime: "2022-11-10 12:22:10",
            EndTime: "2022-11-12 18:22:10",
          },
          {
            Id: 2,
            Name: "编写首页前端页面",
            StartTime: "2022-11-10 12:22:10",
            EndTime: "2022-11-12 18:22:10",
          },
          {
            Id: 3,
            Name: "编写首页前端页面",
            StartTime: "2022-11-10 12:22:10",
            EndTime: "2022-11-12 18:22:10",
          },
        ],
        sensitiveWords: [],
        messageList: [
          {
            Id: 1,
            Sender: {
              Avatar: "",
              Name: "小红",
            },
            Content: "收到请回复",
            Time: "2022-10-24 10:22:33",
          },
          {
            Id: 1,
            Sender: {
              Avatar: "",
              Name: "小红",
            },
            Content: "收到请回复",
            Time: "2022-10-24 10:22:33",
          },
        ],
        reportData: {
          Today: {
            Status: 0,
            WorkTime: 7.5,
            OverWorkTime: 3.5,
          },
          Total: {
            Passed: 0,
            Approving: 0,
            Rejected: 0,
          },
        },
        reportDataCompany: {
          Today: {
            Submited: 0,
            Approving: 0,
            Uncommitted: 0,
          },
          Total: {
            Submited: 0,
            Approving: 0,
            Uncommitted: 0,
          },
        },
        attendanceData: {
          Today: {
            Late: 0,
            EarlyLeave: 0,
            Lose: 0,
            Abstained: 0,
            Attendance: 0,
          },
          Total: {
            Late: 0,
            EarlyLeave: 0,
            Lose: 0,
            Abstained: 0,
            Attendance: 0,
          },
        },
        taskData: {
          Today: {
            Total: 10,
            Complete: 2,
          },
          Total: [
            {
              Time: "2022-11-01",
              Total: 10,
              Complete: 2,
            },
            {
              Time: "2022-11-02",
              Total: 8,
              Complete: 3,
            },
            {
              Time: "2022-11-03",
              Total: 9,
              Complete: 4,
            },
          ],
        },
      },
      reportData: {
        obj: {
          teamId: "",
          id: "",
          day: "",
        },
        FatherDetail: {},
        getDay(day) {
          //监听详情页面日期的改变
          this.obj.day = day;
        },
      },
    };
  },
  computed: {
    vipTip() {
      var tip = {
        isShow: false,
        reason: "",
        title: "",
        content: "",
      };
      if (this.team) {
        if (this.team.ClientCount - this.team.MemberCount <= 10) {
          tip.isShow = true;
          tip.reason = "团队即将满额";
          tip.title = `团队人数已经${this.team.MemberCount}人`;
          tip.content = "即将满额";
        }
      }
      return tip;
    },
    showMore() {
      var hasUser = false;
      hasUser = this.tabType == 1 ? this.user : this.selMem.length > 0;
      if (this.teamValue && hasUser) {
        return true;
      }
      return false;
    },
  },
  methods: {
    toDetail(url) {
      var userId = this.tabType == 1 ? this.user.UsId : this.selMem[0].UsId;
      var identity =
        this.tabType == 1
          ? `UserId=${this.user.UsId} Device=Client TeamId=${this.teamValue}`
          : this.selMem[0].Identity;
      this.$router.push({
        path: url,
        query: {
          teamId: this.teamValue,
          userId: userId,
          identity: identity,
        },
      });
    },
    imgChange,
    showReportDetail() {
      var day = new Date().timeFormat("yyyy-MM-dd");
      //改变详情页面参数
      this.reportData.obj = {
        teamId: this.teamValue,
        id: this.user.UsId,
        day: day,
      };
      this.$modal.show("report");
    },
    /**
     * 全部标记已读
     */
    handleAllRead() {
      this.$confirm("此操作将全部消息标记为已读, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$http
            .post("/Information/AllReadInformation.ashx", {
              teamId: this.teamValue,
            })
            .then((resp) => {
              if (resp.res == 0) {
                this.$message({
                  type: "success",
                  message: "标记成功!",
                });
                this.getMessageList();
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消标记",
          });
        });
    },
    sendWebrtcRequestNoIdentitySync(request, successCallback, failCallback) {
      this.sendWebrtcRequestNoIdentity(request)
        .then((resp) => successCallback?.(resp))
        .catch((error) => {
          failCallback?.(error);
        });
    },
    async sendWebrtcRequestNoIdentity(request) {
      if (this.isLocalStorage) {
        var identity =
          this.tabType == 1
            ? `UserId=${this.user.UsId} Device=Client TeamId=${this.teamValue}`
            : this.selMem[0].Identity;
        await this.Call(identity);
        var b = this.SendRequest(null, identity, request);
        if (!b) {
          throw "发送请求失败";
        }
      }
    },
    handleElementClick(type, event) {
      let endTime;
      const _ = new Date(this.dateRange[1]);
      const date = _.getDate();
      _.setDate(date + 1);
      endTime = _.toLocaleDateString();
      if (type == "ChatKeywordRank") {
        let data = event.data.data.Keyword;
        this.dialogs.chat = {
          open: true,
          options: {
            startTime: new Date(this.dateRange[0]).toLocaleDateString(),
            endTime,
            userId: this.tabType === 1 ? null : this.selMem?.[0]?.UsId ?? null,
            teamId: this.teamValue,
            content: data ? [data] : [],
            users: this.selMem.map((m) => m.UsId),
          },
        };
      } else if (type == "ChatTargetRank") {
        let data = event.data.data.TargetName;
        this.dialogs.software = {
          title: "聊天对象统计 TOP5",
          open: true,
          options: {
            startTime: new Date(this.dateRange[0]).toLocaleDateString(),
            endTime,
            userId: this.tabType === 1 ? null : this.selMem?.[0]?.UsId ?? null,
            teamId: this.teamValue,
            formName: data ? [data] : [],
            users: this.selMem.map((m) => m.UsId),
          },
        };
      } else if (type == "SoftwareUsageRank") {
        let data = event.data.data.SoftwareName;
        this.dialogs.software = {
          title: "软件使用排行 TOP5",
          open: true,
          options: {
            startTime: new Date(this.dateRange[0]).toLocaleDateString(),
            endTime,
            userId: this.tabType === 1 ? null : this.selMem?.[0]?.UsId ?? null,
            teamId: this.teamValue,
            softwareName: data ? [data] : [],
            users: this.selMem.map((m) => m.UsId),
          },
        };
      } else if (type == "SoftwareActiveRank") {
        let data = event.data.data.SoftwareName;
        this.dialogs.software = {
          title: "软件使用活跃度排名 TOP10",
          open: true,
          options: {
            startTime: new Date(this.dateRange[0]).toLocaleDateString(),
            endTime,
            userId: this.tabType === 1 ? null : this.selMem?.[0]?.UsId ?? null,
            teamId: this.teamValue,
            softwareName: data ? [data] : [],
            users: this.selMem.map((m) => m.UsId),
          },
        };
      } else if (type == "SiteRank") {
        let data = event.data.data.Url;
        this.dialogs.website = {
          open: true,
          options: {
            startTime: new Date(this.dateRange[0]).toLocaleDateString(),
            endTime,
            userId: this.tabType === 1 ? null : this.selMem?.[0]?.UsId ?? null,
            teamId: this.teamValue,
            url: data ? [data] : [],
            users: this.selMem.map((m) => m.UsId),
          },
        };
      } else if (type == "SearchRank") {
        let data = event.data.data.Keyword;
        this.dialogs.keyword = {
          open: true,
          options: {
            startTime: new Date(this.dateRange[0]).toLocaleDateString(),
            endTime,
            userId: this.tabType === 1 ? null : this.selMem?.[0]?.UsId ?? null,
            teamId: this.teamValue,
            keyword: data ? [data] : [],
            users: this.selMem.map((m) => m.UsId),
          },
        };
      }
    },
    PadZero(str) {
      //补零
      return new RegExp(/^\d$/g).test(str) ? `0${str}` : str;
    },
    formateShowTime(time) {
      if (!time) {
        return "无";
      }
      if (time) {
        let _time = time * 60 * 60;
        let _seconds = parseInt(_time);
        let hours, mins, seconds;
        let result = "";
        seconds = parseInt(_seconds % 60);
        mins = parseInt((_seconds % 3600) / 60);
        hours = parseInt(_seconds / 3600);
        if (hours) {
          result =
            this.PadZero(hours) +
            ":" +
            this.PadZero(mins) +
            "'" +
            ":" +
            this.PadZero(seconds) +
            '"';
        } else {
          result =
            "00:" +
            this.PadZero(mins) +
            "'" +
            ":" +
            this.PadZero(seconds) +
            '"';
        }

        return result;
      }
    },
    formatClientStatusColor(member) {
      var offline = this.$D.VALUE("client_status", "已离线");
      var active = this.$D.VALUE("client_status", "活动中");
      var free = this.$D.VALUE("client_status", "空闲中");
      switch (member.ClientStatus) {
        case offline:
          return "danger";
        case active:
          return "success";
        case free:
          return "info";
      }
    },
    formatClientStatus(member) {
      var offline = this.$D.VALUE("client_status", "已离线");
      if (offline == member.ClientStatus) {
        var str = "已离线";
        if (member.LastClientOnline) {
          str = `${member.LastClientOnline.timeFormat("yyyy-MM-dd HH:mm")}`;
        }
        return str;
      } else {
        return this.$D.ITEM("client_status", member.ClientStatus).name;
      }
    },
    formatAttendanceStatusColor(status) {
      var none = this.$D.VALUE("at_state", "未打卡");
      var normal = this.$D.VALUE("at_state", "正常");
      var late = this.$D.VALUE("at_state", "迟到/早退");
      var empty = this.$D.VALUE("at_state", "缺卡");
      var skip = this.$D.VALUE("at_state", "旷工");
      var seriousLate = this.$D.VALUE("at_state", "严重迟到/早退");
      var holiday = this.$D.VALUE("at_state", "请假");
      switch (status) {
        case none:
          return "info";
        case normal:
          return "success";
        case late:
        case empty:
        case skip:
        case seriousLate:
          return "danger";
        case holiday:
          return "warning";
      }
    },
    formatAttendanceStatus(status, name) {
      return this.$D.ITEM("at_state", status)[name];
    },
    formatReportStatus(status) {
      return this.$D.ITEM("report_status", status).name;
    },
    tabChange(val) {
      this.tabType = val;
      this.handleGetData();
    },
    /**
     * 选择成员
     */
    getSelMember(arr) {
      this.selMem = arr;
    },
    /**
     * 团队切换
     */
    handleTeamChange() {
      // this.selMem = [];
    },
    resetData() {
      this.userInfo = {
        Id: 0,
        Name: "",
        Avatar: null,
        Department: null,
        ClientStatus: 0,
        LastClientOnline: null,
        Attendance: {
          UpWorkTime: null,
          UpWorkStatus: -1,
          DownWorkTime: null,
          DownWorkStatus: -1,
          TotalTime: 0,
          ShouldEndTime: null,
          WorkStatus: 0,
        },
        AttendanceTotal: {
          Late: 0,
          EarlyLeave: 0,
          Lose: 0,
          Abstained: 0,
          Attendance: 0,
        },
      };
      this.companyData = {
        Total: 0,
        Online: 0,
        Offline: 0,
        Active: 0,
        Free: 0,
      };
      this.workData = {
        taskHistory: [],
        sensitiveWords: [],
        messageList: [],
        reportData: {
          Today: {
            Status: 0,
            WorkTime: 0,
            OverWorkTime: 0,
          },
          Total: {
            Passed: 0,
            Approving: 0,
            Rejected: 0,
          },
        },
        reportDataCompany: {
          Today: {
            Submited: 0,
            Approving: 0,
            Rejected: 0,
          },
          Total: {
            Submited: 0,
            Approving: 0,
            Rejected: 0,
          },
        },
        attendanceData: {
          Today: {
            ClockIn: 0,
            ClockOut: 0,
            Leave: 0,
            ApplyAbnormal: 0,
          },
          Total: {
            ClockTime: 0,
            Lose: 0,
            Leave: 0,
            Late: 0,
            EarlyLeave: 0,
            Abstained: 0,
            AverageHour: 0,
          },
        },
        taskData: {
          Today: {
            Total: 0,
            Complete: 0,
          },
          Total: [],
        },
      };
      this.computerData = {
        chatKeywordRank: [],
        chatTargetRank: [],
        softwareUsageRank: [],
        softwareActiveRank: [],
        siteRank: [],
        searchRank: [],
        fileOperateData: {
          Inner: {
            Create: 0,
            Copy: 0,
            Move: 0,
            Delete: 0,
            Rename: 0,
          },
          Outer: {
            USB: 0,
            Network: 0,
          },
        },
      };
    },
    async handleGetData() {
      this.resetData();
      if (!this.teamValue) {
        this.$message({
          message: "请先选择团队",
          type: "warning",
        });
        return;
      }
      if (this.isLocalStorage && this.tabType == 2 && this.selMem.length == 0) {
        this.$message({
          message: "请先选择成员",
          type: "warning",
        });
        return;
      }
      this.loading = true;
      this.HangUpAll();
      if (this.isLocalStorage) {
        var identity =
          this.tabType == 1
            ? `UserId=${this.user.UsId} Device=Client TeamId=${this.teamValue}`
            : this.selMem[0].Identity;
        try {
          await this.Call(identity);
        } catch (error) {}
      }
      try {
        if (this.tabType == 1) {
          await Promise.all([
            this.getUserInfo(),
            this.getComputerData(),
            this.getTaskHistory(),
            this.getSensitiveWords(),
            this.getMessageList(),
            this.getReportData(),
          ]);
        } else {
          await Promise.all([
            this.getTeamData(),
            this.getComputerData(),
            this.getSensitiveWords(),
            this.getReportDataCompany(),
            this.getTaskData(),
            this.getAttendanceData(),
          ]);
        }
      } finally {
        this.loading = false;
      }
    },
    async getUserInfo() {
      if (!this.teamValue) {
        return;
      }
      let endTime;
      {
        const _ = new Date(this.dateRange[1]);
        const date = _.getDate();
        _.setDate(date + 1);
        endTime = _.toLocaleDateString();
      }
      let params = {
        startTime: new Date(this.dateRange[0]).toLocaleDateString(),
        endTime,
        teamId: this.teamValue,
      };
      var resp = await this.$http.post("/Dashboard/UserInfo.ashx", params);
      if (resp.res == 0) {
        this.userInfo = resp.data;
      }
    },
    async getComputerData() {
      if (!this.teamValue) {
        return;
      }
      let endTime;
      {
        const _ = new Date(this.dateRange[1]);
        const date = _.getDate();
        _.setDate(date + 1);
        endTime = _.toLocaleDateString();
      }
      let params = {
        startTime: new Date(this.dateRange[0]).toLocaleDateString(),
        endTime,
        teamId: this.teamValue,
        users:
          this.tabType == 1 ? [this.user.UsId] : this.selMem.map((m) => m.UsId),
      };
      if (this.isLocalStorage) {
        var identity =
          this.tabType == 1
            ? `UserId=${this.user.UsId} Device=Client TeamId=${this.teamValue}`
            : this.selMem[0].Identity;
        try {
          var data = await this.getComputerDataLocal(identity, params);
          this.computerData = data;
        } catch (error) {
          this.$notify({
            message: `获取电脑数据失败，${error}`,
            type: "info",
          });
        }
        return;
      }
      var resp = await this.$http.post("/Dashboard/ComputerData.ashx", params);
      if (resp.res == 0) {
        this.computerData = resp.data;
        // for (const key in this.computerData) {
        //   if (resp.data.hasOwnProperty.call(resp.data, key)) {
        //     this.computerData[key] = resp.data[key];
        //   }
        // }
      }
    },
    async getTaskHistory() {
      if (!this.teamValue) {
        return;
      }
      let endTime;
      {
        const _ = new Date(this.dateRange[1]);
        const date = _.getDate();
        _.setDate(date + 1);
        endTime = _.toLocaleDateString();
      }
      let params = {
        startTime: new Date(this.dateRange[0]).toLocaleDateString(),
        endTime,
        teamId: this.teamValue,
      };
      var resp = await this.$http.post("/Dashboard/TaskHistory.ashx", params);
      if (resp.res == 0) {
        this.workData.taskHistory = resp.data;
      }
    },
    async getSensitiveWords() {
      if (!this.teamValue) {
        return;
      }
      let endTime;
      {
        const _ = new Date(this.dateRange[1]);
        const date = _.getDate();
        _.setDate(date + 1);
        endTime = _.toLocaleDateString();
      }
      let params = {
        startTime: new Date(this.dateRange[0]).toLocaleDateString(),
        endTime,
        teamId: this.teamValue,
        users:
          this.tabType == 1 ? [this.user.UsId] : this.selMem.map((m) => m.UsId),
      };
      if (this.isLocalStorage) {
        var identity =
          this.tabType == 1
            ? `UserId=${this.user.UsId} Device=Client TeamId=${this.teamValue}`
            : this.selMem[0].Identity;
        try {
          var data = await this.getSensitiveWordsLocal(identity, params);
          this.workData.sensitiveWords = data;
        } catch (error) {
          this.$notify({
            message: `获取敏感词数据失败，${error}`,
            type: "info",
          });
        }
        return;
      }
      var resp = await this.$http.post(
        "/Dashboard/SensitiveWords.ashx",
        params
      );
      if (resp.res == 0) {
        this.workData.sensitiveWords = resp.data;
      }
    },
    async getMessageList() {
      if (!this.teamValue) {
        return;
      }
      let endTime;
      {
        const _ = new Date(this.dateRange[1]);
        const date = _.getDate();
        _.setDate(date + 1);
        endTime = _.toLocaleDateString();
      }
      let params = {
        startTime: new Date(this.dateRange[0]).toLocaleDateString(),
        endTime,
        teamId: this.teamValue,
      };
      var resp = await this.$http.post("/Dashboard/MessageList.ashx", params);
      if (resp.res == 0) {
        this.workData.messageList = resp.data;
      }
    },
    async getAttendanceData() {
      if (!this.teamValue) {
        return;
      }
      let endTime;
      {
        const _ = new Date(this.dateRange[1]);
        const date = _.getDate();
        _.setDate(date + 1);
        endTime = _.toLocaleDateString();
      }
      let params = {
        startTime: new Date(this.dateRange[0]).toLocaleDateString(),
        endTime,
        teamId: this.teamValue,
        users:
          this.tabType == 1 ? [this.user.UsId] : this.selMem.map((m) => m.UsId),
      };
      var resp = await this.$http.post(
        "/Dashboard/AttendanceData.ashx",
        params
      );
      if (resp.res == 0) {
        this.workData.attendanceData = resp.data;
      }
    },
    async getReportData() {
      if (!this.teamValue) {
        return;
      }
      let endTime;
      {
        const _ = new Date(this.dateRange[1]);
        const date = _.getDate();
        _.setDate(date + 1);
        endTime = _.toLocaleDateString();
      }
      let params = {
        startTime: new Date(this.dateRange[0]).toLocaleDateString(),
        endTime,
        teamId: this.teamValue,
      };
      var resp = await this.$http.post("/Dashboard/ReportData.ashx", params);
      if (resp.res == 0) {
        this.workData.reportData = resp.data;
      }
    },
    async getReportDataCompany() {
      if (!this.teamValue) {
        return;
      }
      let endTime;
      {
        const _ = new Date(this.dateRange[1]);
        const date = _.getDate();
        _.setDate(date + 1);
        endTime = _.toLocaleDateString();
      }
      let params = {
        startTime: new Date(this.dateRange[0]).toLocaleDateString(),
        endTime,
        teamId: this.teamValue,
        users:
          this.tabType == 1 ? [this.user.UsId] : this.selMem.map((m) => m.UsId),
      };
      var resp = await this.$http.post(
        "/Dashboard/ReportDataTeam.ashx",
        params
      );
      if (resp.res == 0) {
        this.workData.reportDataCompany = resp.data;
      }
    },
    async getTaskData() {
      if (!this.teamValue) {
        return;
      }
      let endTime;
      {
        const _ = new Date(this.dateRange[1]);
        const date = _.getDate();
        _.setDate(date + 1);
        endTime = _.toLocaleDateString();
      }
      let params = {
        startTime: new Date(this.dateRange[0]).toLocaleDateString(),
        endTime,
        teamId: this.teamValue,
        users:
          this.tabType == 1 ? [this.user.UsId] : this.selMem.map((m) => m.UsId),
      };
      var resp = await this.$http.post("/Dashboard/TaskData.ashx", params);
      if (resp.res == 0) {
        this.workData.taskData = resp.data;
      }
    },
    async getTeamData() {
      if (!this.teamValue) {
        return;
      }
      let params = {
        teamId: this.teamValue,
      };
      var resp = await this.$http.post("/Dashboard/TeamData.ashx", params);
      if (resp.res == 0) {
        this.companyData = resp.data;
      }
    },
    //webrtc通用方法
    async callMember(identity) {
      if (this.isLocalStorage) {
        try {
          await this.Call(identity);
        } catch (error) {
          // console.log(error);
        }
      }
    },
    getComputerDataLocal(clientIdentity, data) {
      var _this = this;
      var promise = new Promise((resolve, reject) => {
        if (!_this.isLocalStorage) {
          reject("当前团队未启用本地存储");
        }
        var request = new Request();
        request.Url = "/Process/ComputerData";
        request.ContentType = "Json";
        const token = _this.$xStorage.getItem("token");
        request.Headers.Authorization = token;
        request.Body = JSON.stringify(data);
        request.OnError = function (error) {
          reject(error);
        };
        request.OnResponse = function (response) {
          let result = response.Result;
          if (result.State) {
            resolve(result.Data);
          } else {
            reject(result.Message);
          }
        };
        this.Call(clientIdentity)
          .then(() => {
            var b = this.SendRequest(null, clientIdentity, request);
            if (!b) {
              reject("发送请求失败");
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
      return promise;
    },
    getSensitiveWordsLocal(clientIdentity, data) {
      var _this = this;
      var promise = new Promise((resolve, reject) => {
        if (!_this.isLocalStorage) {
          reject("当前团队未启用本地存储");
        }
        var request = new Request();
        request.Url = "/Process/SensitiveWords";
        request.ContentType = "Json";
        const token = _this.$xStorage.getItem("token");
        request.Headers.Authorization = token;
        request.Body = JSON.stringify(data);
        request.OnError = function (error) {
          reject(error);
        };
        request.OnResponse = function (response) {
          let result = response.Result;
          if (result.State) {
            resolve(result.Data);
          } else {
            reject(result.Message);
          }
        };
        this.Call(clientIdentity)
          .then(() => {
            var b = this.SendRequest(null, clientIdentity, request);
            if (!b) {
              reject("发送请求失败");
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
      return promise;
    },
  },
  mounted() {
    this.resetData();
  },
  beforeDestroy() {
    this.HangUpAll();
  },
};
